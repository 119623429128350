import React from 'react';

import Layout from '../components/layout';
import img404 from '../images/404.jpg';

const NotFoundPage = () => (
  <Layout>
    <div className="container flex center">
      <img alt="" src={img404} />
    </div>
  </Layout>
);

export default NotFoundPage;
